import React, {useState} from "react";

function App() {
  // track state of round and rounds
  const [round, setRound] = useState({duration:"", smallBlind:"", bigBlind: "", ante: ""});
  const [rounds, updateRounds] = useState([]);
  const [timerRunning, updateTimerState] = useState(false);
  const [pausedTime, updatePausedTime] = useState(0);
  
  let currentRound = 0;

  function sound(){
    let audio = document.getElementById("audio");
    audio.play();
  }

  // set the user input round values 
  function handleChange(event) {
    const { name, value } = event.target;
    setRound(prevValues => {
      return {
        ...prevValues,
        [name]: value
      }
    })
  }
  
  //  add the user input round to the rounds array if user inputs not empty
  function addRound() {
    if (round.duration && round.smallBlind && round.bigBlind && round.ante) {
      updateRounds(prevRounds => {
        return [...prevRounds, round]
      })
      setRound({
        duration:"",
        smallBlind:"",
        bigBlind:"",
        ante:""
      })
    }
  }

  // delete round from rounds array 
  function deleteRound(index) {
    console.log('delete clicked')
    updateRounds(prevRounds => prevRounds.filter((rounds, i) => i !== index));
  }

  // clear all rounds
  function clearAll() {
    updateRounds([]);
  }

  // display the current round info
  function changeElements() {
    document.getElementById("round").innerHTML = currentRound + 1;
    document.getElementById("small-blind").innerHTML = rounds[currentRound].smallBlind;
    document.getElementById("big-blind").innerHTML = rounds[currentRound].bigBlind;
    document.getElementById("ante").innerHTML = rounds[currentRound].ante;
  }

  function play() {
    console.log(timerRunning)
    console.log(pausedTime)

    if (timerRunning) {
      console.log('stop the timer');
      console.log("current rund is" + currentRound)
      updateTimerState(false);
      clearInterval(window.interval);
    } else {
      if (pausedTime !== 0) {
        console.log("paused time is not 0, start timer")
        countDown(pausedTime)
        updateTimerState(true);
      } else {
        // if rounds and rounds.length are truthy
        if (rounds && rounds.length) {
          console.log('play clicked')
          changeElements();

          // begin timer
          countDown(rounds[currentRound].duration);
          updateTimerState(true);

        } else {
          console.log('rounds empty')
        }
      }
    }
  }

  // timer functionality 
  function countDown(currentRoundDuration) {

    currentRoundDuration *= 60;
    let display = document.querySelector('#duration');
    let timer = currentRoundDuration;

    window.interval = setInterval(function() {
      let minutes = parseInt(timer / 60, 10);
      let seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds;

      // store remaining time at each interval in case pause is clicked so we can restart with that num
      updatePausedTime(timer / 60);
      console.log(pausedTime)

      if (--timer < 0) {
        // stop the timer when it reaches 0 
        clearInterval(window.interval);
        // play a sound!
        sound();
        // call the next round if it exists
        if (rounds[currentRound+1]) {
          currentRound += 1;
          changeElements();
          countDown(rounds[currentRound].duration);
        }
      }
    }, 1000)

  }

  return (

    <div className="app">

      <div className="top-half">
        <div className="section-1 flex-true-center">
          <div className="heading">
            <h1>Simple<span>Poker</span>Timer</h1>
            <p>The internet's easiest Poker timer!</p>
          </div>

          <div className="round-info">
            <div><h3>Round</h3><span id="round">#</span></div>
            <div><h3>Time Left</h3><span id="duration">--:--</span></div>
            <div><h3>Small Blind</h3><span id="small-blind">-</span></div>
            <div><h3>Big Blind</h3><span id="big-blind">-</span></div>
            <div><h3>Ante</h3><span id="ante">-</span></div>
          </div>
        </div>

        <div className="section-2 flex-true-center">
          <div className="form">
              <input name="duration" onChange={handleChange} value={round.duration} placeholder="Duration (minutes)" type="number" />
              <input name="ante" onChange={handleChange} value={round.ante} placeholder="Ante" type="number" />
              <input name="smallBlind" onChange={handleChange} value={round.smallBlind} placeholder="Small Blind" type="number" />
              <input name="bigBlind" onChange={handleChange} value={round.bigBlind} placeholder="Big Blind" type="number" />
          </div>

          <div className="controls">
            <button onClick={addRound}>
              <span>Add Round</span>
            </button>
            <button className={ timerRunning ? "red" : "yellow" } id="play-btn" onClick={play}>
                { timerRunning ? <span>Pause</span> : <span>Play</span> }
            </button>
            { rounds.length > 1 ? 
              <button onClick={clearAll}>
                <span>Clear All</span>
              </button> 
              : null
            }
          </div>
        </div>
      </div>
      
      <div className="bottom-half">
        <div className="section-3 flex-true-center">
          <div className="rounds">
            <table>
              <thead className="table-head">
                <tr>
                  <th>Round</th>
                  <th>Time</th>
                  <th>Ante</th>
                  <th>SB</th>
                  <th>BB</th>
                </tr>
              </thead>
              <tbody>
                {rounds.map((roundItem, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{roundItem.duration}</td>
                      <td>{roundItem.ante}</td>
                      <td>{roundItem.smallBlind}</td>
                      <td>{roundItem.bigBlind}</td>
                      <td onClick={deleteRound.bind(this, index)}>X</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default App;
